import React, { useState } from "react";
import "./MainNotification.scss";
import Datatable from "../../../components/datatable/Datatable";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import Navbar from "../../../components/Navbar/Navbar";
import Actions from "../../../components/Actions/Actions";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";

function MainNotification() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let data = [
    {
      id: 1,
      name: "New Order",
      describtion: "Bla bla bla",
    },
    {
      id: 2,
      name: "New Order",
      describtion: "Bla bla bla",
    },
    {
      id: 3,
      name: "New Order",
      describtion: "Bla bla bla",
    },
  ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "name",
      headerName: t("Title"),
      width: 400,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },
    {
      field: "describtion",
      headerName: t("Describtion"),
      width: 400,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.describtion}`}</div>;
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="Notification" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Notifications")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={data}
                filter="Notification"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="Notification"
                params={data}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          <Datatable
            userColumns={userColumns}
            userRows={data}
            onSelectionModelChange={handleSelectionChange}
          />
        </div>
      </div>
    </div>
  );
}

export default MainNotification;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createState = createAsyncThunk(
  "state/createState",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/states`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllState = createAsyncThunk(
  "state/getAllState",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/states`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteState = createAsyncThunk(
  "state/deleteState",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/states/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleState = createAsyncThunk(
  "state/getSingleState",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/states/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateState = createAsyncThunk(
  "state/updateState",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(`${BASE_URL}/states/${arg.id}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let createCity = createAsyncThunk(
  "state/createCity",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/cities`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateCity = createAsyncThunk(
  "city/updateCity",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(`${BASE_URL}/cities/${arg.id}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteCity = createAsyncThunk(
  "city/deleteCity",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/cities/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let stateslice = createSlice({
  name: "state",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    dataCreate: false,
    dataUpdateCity: false,
    dataUpdate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    loadingUpdate: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorCaptain: false,
    errorSingle: false,
    errorCity: false,
    errorDelete: false,
    city: false,
  },
  reducers: {
    resetsingleDataState: (state, action) => {
      state.data = action.payload;
    },
    resetAllStateAction: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreateAction: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdateAction: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createState

    [createState.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createState.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createState.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //createCity

    [createCity.pending]: (state, action) => {
      state.loadingBTN = true;
      state.errorSingle = false;
    },
    [createCity.fulfilled]: (state, action) => {
      state.errorSingle = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createCity.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateState

    [updateState.pending]: (state, action) => {
      state.loadingBTN = true;
      state.errorSingle = false;
    },
    [updateState.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.errorSingle = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateState.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateCity

    [updateCity.pending]: (state, action) => {
      state.loadingUpdate = true;
      state.errorSingle = false;
    },
    [updateCity.fulfilled]: (state, action) => {
      state.loadingUpdate = false;
      state.errorSingle = false;
      state.dataUpdateCity = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateCity.rejected]: (state, action) => {
      state.loadingUpdate = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleState

    [getSingleState.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleState.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleState.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteState

    [deleteState.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteState.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteState.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteCity

    [deleteCity.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteCity.fulfilled]: (state, action) => {
      // state.singleData = state.singleData.filter(
      //   (e) => !action.payload.idList.includes(e.id)
      // );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteCity.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllState

    [getAllState.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllState.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default stateslice.reducer;
export let {
  resetAllStateAction,
  resetsingleDataState,
  resetDataCreateAction,
  resetDataUpdateAction,
} = stateslice.actions;

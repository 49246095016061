import React, { useMemo, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const LocationPicker = ({
  onSelectLocation,
  singleData,
  nochange,
  width,
  height,
}) => {
  const containerStyle = {
    width: width ? "width" : "400px",
    height: height ? height : "300px",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCPsxZeXKcSYK1XXw0O0RbrZiI_Ekou5DY",
  });

  const [map, setMap] = React.useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const center = useMemo(() => {
    if (singleData && singleData.latitude && singleData.longitude) {
      return {
        lat: parseFloat(singleData.latitude),
        lng: parseFloat(singleData.longitude),
      };
    }
    return { lat: 33.52507980041209, lng: 36.22133553952544 }; // Default center
  }, [singleData]);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);

      setMap(map);

      if (!nochange) {
        // Attach the click event listener only if `nochange` is false
        map.addListener("click", (event) => {
          const clickedLocation = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          setMarkerPosition(clickedLocation);
          onSelectLocation(clickedLocation);
        });
      }
    },
    [onSelectLocation, center, nochange]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default LocationPicker;

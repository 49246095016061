import React, { useEffect, useState } from "react";
import "./PopUpDeliveyAttrebute.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createDeviryAttrebute,
  getAllDeviryAttrebute,
  getSingleDeliveyAttrebute,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataDeveryAttrebute,
  updateDelivryAttrebute,
} from "../../../store/deleviryattrebute";

function PopUpDeliveyAttrebute({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.deliveryattrebute);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllDeviryAttrebute({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDataDeveryAttrebute(""));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleDeliveyAttrebute({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      console.log("addd");
      dispatch(createDeviryAttrebute({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateDelivryAttrebute({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Delivery Attrebute")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Delivery Attrebute")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Delivery Attrebute Name")}:{" "}
                  <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the Delivery Attrebute name"
                  defaultValue={type === "edit" ? singleData.name : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpDeliveyAttrebute;

import img1 from "../../assits/logo_app.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import "./sidebar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTranslation } from "react-i18next";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import checkpermation from "../../Permissions";
import HomeIcon from "@mui/icons-material/Home";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import DiscountIcon from '@mui/icons-material/Discount';
import { Logout } from "../../store/authslice";

function Sidebar() {
  const [t, i18n] = useTranslation();
  let { showslider } = useSelector((state) => state.persistTheme.auth);
  let [showMenu, setShowMenu] = useState({});
  let [toggle, setToggle] = useState(true);
  let toggleMenu = (n) => {
    setToggle(!toggle);
    setShowMenu({ name: n, state: toggle });
  };
  let dispatch = useDispatch();

  let navigate = useNavigate();
  return (
    <div className={!showslider ? "big" : "big2"}>
      <div className="list img-logo">
        <img src={img1} alt="..." />
        <h5>{t("Farmy")}</h5>
        <hr />
      </div>
      <div className="list">
        <ul>
          <NavLink to="/">
            <DashboardIcon className="icon-s" />
            <span> {t("Dashboard")}</span>
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          <NavLink to="/users">
            <PeopleAltIcon className="icon-s" />
            {t("Users")}
          </NavLink>
          <NavLink to="/captains">
            <DirectionsBikeIcon className="icon-s" />
            {t("Captains")}
          </NavLink>

          <NavLink to="/areas">
            <LocationOnIcon className="icon-s" />
            {t("Areas")}
          </NavLink>

          <NavLink to="/state">
            <HomeIcon className="icon-s" />
            {t("States")} {"&"} {t("Cities")}
          </NavLink>

          <NavLink to="/roles-permision">
            <ManageAccountsIcon className="icon-s" />
            {t("Roles And Permission")}
          </NavLink>

          <NavLink to="/sellers">
            <LocationCityIcon className="icon-s" />
            {t("Sellers")}
          </NavLink>
          <NavLink to="/coupons">
            <DiscountIcon className="icon-s" />
            {t("Coupons")}
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          {/**start first dropdown */}
          <a onClick={() => toggleMenu("Courses")}>
            <InventoryIcon className="icon-s" />
            {t("Product Management")}
            {showMenu.name === "Courses" && showMenu.state ? (
              <ArrowDropDownIcon
                style={
                  i18n.language === "en"
                    ? { marginLeft: "auto", marginRight: "20px" }
                    : { marginRight: "auto", marginLeft: "20px" }
                }
              />
            ) : (
              <ArrowDropUpIcon
                style={
                  i18n.language === "en"
                    ? { marginLeft: "auto", marginRight: "20px" }
                    : { marginRight: "auto", marginLeft: "20px" }
                }
              />
            )}
          </a>

          <ul
            className={`list-unstyled menu-list ${
              showMenu.name === "Courses" && showMenu.state ? "active" : ""
            }`}
          >
            <NavLink to="/product">
              <ArrowRightIcon className="icon-s" />
              {t("Product")}
            </NavLink>
            <NavLink to="/categories">
              <ArrowRightIcon className="icon-s" />
              {t("Categories")}
            </NavLink>

            <NavLink to="/sub-categories">
              <ArrowRightIcon className="icon-s" />
              {t("Sub Categories")}
            </NavLink>

            <NavLink to="/product-variant">
              <ArrowRightIcon className="icon-s" />
              {t("Product Attribute")}
            </NavLink>

            <NavLink to="/commission">
              <ArrowRightIcon className="icon-s" />
              {t("Commission")}
            </NavLink>
          </ul>
          {/**end first dropdown */}

          {/**start second dropdown */}
          <a onClick={() => toggleMenu("Additions")}>
            <AutoFixHighIcon className="icon-s" />
            {t("Additions")}
            {showMenu.name === "Additions" && showMenu.state ? (
              <ArrowDropDownIcon
                style={
                  i18n.language === "en"
                    ? { marginLeft: "auto", marginRight: "20px" }
                    : { marginRight: "auto", marginLeft: "20px" }
                }
              />
            ) : (
              <ArrowDropUpIcon
                style={
                  i18n.language === "en"
                    ? { marginLeft: "auto", marginRight: "20px" }
                    : { marginRight: "auto", marginLeft: "20px" }
                }
              />
            )}
          </a>

          <ul
            className={`list-unstyled menu-list ${
              showMenu.name === "Additions" && showMenu.state ? "active" : ""
            }`}
          >
            <NavLink to="/delivery-methods">
              <ArrowRightIcon className="icon-s" />
              {t("Delivery Methods")}
            </NavLink>
            <NavLink to="/delivery-attributes">
              <ArrowRightIcon className="icon-s" />
              {t("Delivery Attributes")}
            </NavLink>
            <NavLink to="/delivery-message">
              <ArrowRightIcon className="icon-s" />
              {t("Delivery Message")}
            </NavLink>
            <NavLink to="/banners">
              <ArrowRightIcon className="icon-s" />
              {t("Banners")}
            </NavLink>
            <NavLink to="/gifts">
              <ArrowRightIcon className="icon-s" />
              {t("Gifts")}
            </NavLink>
          </ul>

          {/**end second dropdown */}

          {/* <NavLink to="/order">
            <ShoppingCartIcon className="icon-s" />
            {t("Orders")}
          </NavLink> */}

          {/* <NavLink to="/notification">
            <NotificationsIcon className="icon-s" />
            {t("Notifications")}
          </NavLink> */}
        </ul>
      </div>

      {/**
               <div className="list">
        <ul className="list-unstyled">
          <NavLink to="/faq-admin">
            <QuestionAnswerIcon className="icon-s" />
            {t("FAQ")}
          </NavLink>

          <NavLink to="/contact-us-admin">
            <ChatOutlinedIcon className="icon-s" />
            {t("Conatct Us")}
          </NavLink>

          <NavLink to="/order">
            <LocalMallIcon className="icon-s" />
            {t("Orders")}
          </NavLink>
        </ul>
      </div>

  */}
      <div className="list">
        <ul className="list-unstyled">
          <NavLink to="/settings">
            <SettingsSuggestIcon className="icon-s" />
            {t("Privacy Policy")}
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <ul className="list-unstyled">
          <div
            onClick={() => {
              dispatch(Logout());
              navigate("/login");
            }}
          >
            <LogoutIcon className="icon-s" />
            {t("Logout")}
          </div>
        </ul>
      </div>

      {/* <div className="list">
        <h6>Theme</h6>
       *<div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>*
        <div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>
      </div>*/}
    </div>
  );
}

export default Sidebar;

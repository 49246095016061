import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createbanners = createAsyncThunk(
  "banners/createbanners",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/banners`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllBanners = createAsyncThunk(
  "banners/getAllBanners",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/banners`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteBanners = createAsyncThunk(
  "banners/deleteBanners",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/banners/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleBanners = createAsyncThunk(
  "banners/getSingleBanners",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/banners/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateBanners = createAsyncThunk(
  "banners/updateBanners",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(`${BASE_URL}/banners/${arg.id}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let bannersslice = createSlice({
  name: "banners",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
    errorAll: false,
  },
  reducers: {
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDatabanners: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createbanners

    [createbanners.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createbanners.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createbanners.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateBanners

    [updateBanners.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateBanners.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateBanners.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleBanners

    [getSingleBanners.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleBanners.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleBanners.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteBanners

    [deleteBanners.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteBanners.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteBanners.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllBanners

    [getAllBanners.pending]: (state, action) => {
      state.loading = true;
      state.errorAll = false;
      state.data = false;
    },
    [getAllBanners.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.errorAll = false;
      state.loading = false;
    },
    [getAllBanners.rejected]: (state, action) => {
      state.loading = false;
      state.errorAll = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default bannersslice.reducer;
export let {
  resetAllSellerAction,
  resetsingleDatabanners,
  resetDataCreate,
  resetDataUpdate,
} = bannersslice.actions;

import React, { useEffect, useMemo, useState } from "react";
import "./AttrAndValProduct.scss";
import { useDispatch, useSelector } from "react-redux";
import { getValueAttribute } from "../../store/attribute";
import CircularIndeterminate from "../CircularIndeterminate/CircularIndeterminate";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

// function ValueProduct({ attributes, singelData, setGetAttre }) {

// }
// ... (previous imports and code)

function AttrAndValProduct({ singelData, setGetAttre, attributes }) {
  // const {
  //   data: attributes,
  //   loading: loadingAttr,
  //   error: errorAttre,
  //   values,
  //   loadingValue,
  //   errorValue,
  // } = useSelector((state) => state.attributes);

  // let attributes = [
  //   {
  //     id: 7,
  //     name: "عدد القطع",
  //     values: [
  //       {
  //         value: "1",
  //       },
  //       {
  //         value: "2",
  //       },
  //     ],
  //   },
  //   {
  //     id: 8,
  //     name: "الوزن",
  //     values: [
  //       {
  //         value: "200g",
  //       },
  //       {
  //         value: "500g",
  //       },
  //     ],
  //   },
  // ];

  // let singelData = [
  //   {
  //     id: 7,
  //     name: "عدد القطع",
  //     value: "2",
  //     values: [
  //       {
  //         value: "1",
  //       },
  //       {
  //         value: "2",
  //       },
  //     ],
  //   },
  //   {
  //     id: 8,
  //     name: "الوزن",
  //     value: "200g",
  //     values: [
  //       {
  //         value: "200g",
  //       },
  //       {
  //         value: "500g",
  //       },
  //     ],
  //   },
  // ];

  let { name: type, id } = useParams();
  const [t, i18n] = useTranslation();

  const [selectedData, setSelectedData] = useState([]);
  const [dataAttre, setDataAttre] = useState([attributes]);

  const [sections, setSections] = useState([
    {
      name: null,
      arrValues: [],
    },
  ]);

  // useEffect(()=>{
  //   setDataAttre([attributes])
  // },[attributes])

  useEffect(() => {
    if (type === "edit" && singelData) {
      // Initialize sections from singelData
      const initialSections = singelData.map((data) => ({
        name: data.name,
        arrValues: data.values || [], // Assuming values array exists in singelData
      }));

      // Initialize selectedData from singelData
      const initialSelectedData = singelData.map((data) => ({
        id: data.id,
        value: data.value,
      }));

      const initialDataAttre = singelData.map((data) => {
        const filteredAttributes = attributes.filter(
          (attr) => attr.name === data.name
        );
        return filteredAttributes.map((attr) => ({
          ...attr,
          value: data.value,
        }));
      });

      setDataAttre(initialDataAttre);
      setSections(initialSections);
      setSelectedData(initialSelectedData);
    }
  }, [id]);

  const addNewSection = (e) => {
    e.preventDefault();

    // Ensure there are available attributes to add
    if (dataAttre.length < attributes.length) {
      // Filter out the selected attribute from the original attributes array
      const filteredAttributes = attributes.filter(
        (attr) => !sections.some((section) => section.name === attr.name)
      );

      // Add a new section with filtered attributes
      setSections((prevSections) => [
        ...prevSections,
        {
          name: null,
          arrValues: [],
        },
      ]);

      // Add the filtered attributes to dataAttre
      setDataAttre((prevDataAttre) => [...prevDataAttre, filteredAttributes]);
    }
  };

  const removeSection = (indexToRemove) => {
    setSections((prevSections) =>
      prevSections.filter((_, index) => index !== indexToRemove)
    );

    // Remove the corresponding object from selectedData
    setSelectedData((prevSelectedData) =>
      prevSelectedData.filter((_, index) => index !== indexToRemove)
    );

    // Remove the corresponding array from dataAttre
    setDataAttre((prevDataAttre) =>
      prevDataAttre.filter((_, index) => index !== indexToRemove)
    );
  };

  const getdata = async (e, index) => {
    const selectedAttributeName = e.target.value;

    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index] = {
        ...newSections[index],
        name: selectedAttributeName,
        arrValues: [], // Reset arrValues when selecting a new attribute
      };
      return newSections;
    });

    // Fetch values for the selected attribute from the available attributes
    const selectedAttribute = dataAttre[index].find(
      (attr) => attr.name === selectedAttributeName
    );

    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index] = {
        ...newSections[index],
        arrValues: selectedAttribute ? selectedAttribute.values : [],
      };
      return newSections;
    });

    // Update selectedData state
    setSelectedData((prevSelectedData) => {
      const newSelectedData = [...prevSelectedData];
      newSelectedData[index] = {
        id: selectedAttribute ? selectedAttribute.id : null,
        value: null,
      };
      return newSelectedData;
    });
  };

  const updateSelectedValue = (index, selectedValue) => {
    setSelectedData((prevSelectedData) => {
      const newSelectedData = [...prevSelectedData];
      newSelectedData[index] = {
        ...newSelectedData[index],
        value: selectedValue,
      };
      return newSelectedData;
    });
  };

  setGetAttre(selectedData);

  return (
    <div className="row">
      <div className="btn-list col-md-12">
        <button
          className="btn btn-primary-rgba mb-3"
          onClick={(e) => addNewSection(e)}
          disabled={
            !sections[sections.length - 1]?.name ||
            sections.length === attributes.length
          }
        >
          <AddIcon /> {t("Add New")}
        </button>
      </div>

      {sections.map((section, index) => (
        <div className="col-md-4" key={index}>
          <div className="box-vari">
            <div className="">
              <div className="form-group">
                <label className="text-dark" htmlFor="Attribute">
                  {t("Attribute")}: <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getdata(e, index)}
                  name="selectedAttribute"
                  value={section.name || t("Select Attribute Please")}
                >
                  <option disabled={true} value={t("Select Attribute Please")}>
                    {t("Select Attribute Please")}
                  </option>
                  {dataAttre.length > 0 &&
                    dataAttre[index]?.map((attr) => (
                      <option key={attr.id} value={attr.name}>
                        {attr.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="">
              <div className="form-group">
                <label className="text-dark" htmlFor="Value">
                  {t("Value")}: <sup className="text-danger">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  name="value_id"
                  disabled={!section.name}
                  value={selectedData[index]?.value || t("Select Value Please")}
                  onChange={(e) => updateSelectedValue(index, e.target.value)}
                >
                  <option disabled={true} value={t("Select Value Please")}>
                    {t("Select Value Please")}
                  </option>
                  {section.arrValues.map((value, index) => (
                    <option key={index} value={value.value}>
                      {value.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {sections.length > 1 && (
              <div className="box-icon" onClick={() => removeSection(index)}>
                <ClearIcon />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AttrAndValProduct;

import React, { useEffect, useState } from "react";
import "./MainRegions.scss";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/Navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import Switch from "@mui/material/Switch";
import Actions from "../../../components/Actions/Actions";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import { useDispatch, useSelector } from "react-redux";
import { getAllAreas, resetsingleDataArea } from "../../../store/area";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { ChangeStatus } from "../../../store/authslice";

function MainRegions() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  // let data = [
  //   {
  //     id: 1,
  //     name: "Rukn Al Den",
  //     status: "0",
  //   },
  //   {
  //     id: 2,
  //     name: "Malki",
  //     status: "1",
  //   },
  //   {
  //     id: 3,
  //     name: "Mashro3",
  //     status: "1",
  //   },
  //   {
  //     id: 4,
  //     name: "Muhajren",
  //     status: "0",
  //   },
  // ];

  let { data: areas, loading, error } = useSelector((state) => state.areas);

  useEffect(() => {
    // Find the first switch with is_default === "1" and set it as the initially selected switch
    const initiallySelectedSwitch =
      areas && areas.find((area) => area.is_default === "1");
    if (initiallySelectedSwitch) {
      setSelectedSwitch(initiallySelectedSwitch.id);
    }
  }, [areas]); // Ensure useEffect runs when areas change

  const [selectedSwitch, setSelectedSwitch] = useState(null);

  // Function to handle switch change
  const handleSwitchChange = (event, switchId) => {
    if (event.target.checked) {
      // If the switch is checked, set it as the selected switch
      setSelectedSwitch(switchId);
    } else {
      // If the switch is unchecked, clear the selected switch
      setSelectedSwitch(null);
    }
  };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 200 },
    {
      field: "name",
      headerName: t("Areas"),
      width: 550,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 200,
      renderCell: (params) => {
        const switchId = params.row.id; // Assuming id is a unique identifier for the switch
        return (
          <div className="status">
            <Switch
              {...label}
              checked={selectedSwitch === switchId}
              onChange={(event) => {
                handleSwitchChange(event, switchId);
                getStatus(event, params.row.id);
              }}
              disabled={selectedSwitch !== null && selectedSwitch !== switchId}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="regions" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAreas({ lang: i18n.language }));
    dispatch(resetsingleDataArea(""));
  }, []);

  let getStatus = (e, id) => {
    let data = {
      model_id: id,
      model_class: "Area",
      attribute: "is_default",
      value: e.target.checked ? 1 : 0,
    };
    dispatch(ChangeStatus(data));
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Areas")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={areas}
                filter="regions"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="regions"
                params={areas}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={areas && areas}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainRegions;

import React, { Fragment, useState } from "react";
import "./CollapsibleTable.scss";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tooltip from "@mui/material/Tooltip";
import TableFooter from "@mui/material/TableFooter";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as XLSX from "xlsx";
import EditIcon from "@mui/icons-material/Edit";
import ModalDelete from "../ModalDelete/ModalDelete";
import ModalMulti from "../ModalMulti/ModalMulti";
import { useTranslation } from "react-i18next";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";

function CollapsibleTable({ orders }) {
  const [t, i18n] = useTranslation();

  let mm = {
    id: 3,
    title: "new Marker",
    created_by: 1,
    status: 1,
    approved: 1,
    created_at: "2024-01-06T12:49:38.000000Z",
    updated_at: "2024-01-11T18:16:24.000000Z",
    deleted_at: null,
    uploads: [
      {
        id: 65,
        file_original_name: "2",
        file_name: "uploads/all/AfOI58JkhDQT2voJYxQTmipp1m8pD4P5D7czi7qL.jpg",
        user_id: 1,
        file_size: 169376,
        extension: "jpg",
        type: "image",
        section: null,
        external_link: null,
        created_at: "2023-08-26T22:20:59.000000Z",
        updated_at: "2023-08-26T22:20:59.000000Z",
        deleted_at: null,
        pivot: {
          high_light_id: 3,
          upload_id: 65,
          id: 6,
          title: null,
          start_time: null,
        },
      },
      {
        id: 57,
        file_original_name: "1",
        file_name: "uploads/all/wEUJLRmRI7Kg0NYcUYcJ753NVxQRkOQfqiDQN6Ht.png",
        user_id: 1,
        file_size: 690606,
        extension: "png",
        type: "image",
        section: null,
        external_link: null,
        created_at: "2023-08-19T19:50:47.000000Z",
        updated_at: "2023-08-19T19:50:47.000000Z",
        deleted_at: null,
        pivot: {
          high_light_id: 3,
          upload_id: 57,
          id: 8,
          title: null,
          start_time: null,
        },
      },
    ],
  };

  let navigate = useNavigate();
  let gotToFn = (go) => {
    let { path, id } = go;
    navigate(`${path}/${id}`);
  };

  console.log("orders==>", orders);

  let newdata = [
    {
      id: 90,
      order_number: "00001",
      user_name: "mayar Al Jabasene",
      Full: 25500,
      captain_name: "",
      ontime: "0",
      status: "pending",
      Payment_Method: "cash",
      history: [
        {
          id: 151,
          product_name: "tomato",
          quantity: 2,
          price: 1200,
          discount: 1200,
          tax: 200,
          commission: 200,
          total: 1200,
          status: "pending",
          description: "<p>qwe</p>",
        },
        {
          id: 153,
          product_name: "Corn",
          quantity: 2,
          price: 700,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 1400,
          status: "pending",
          description: "<p>qwe</p>",
        },
        {
          id: 11,
          product_name: "Kosa",
          quantity: 1,
          price: 2000,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 2000,
          status: "pending",
          description: "<p>qwe</p>",
        },
      ],
    },
    {
      id: 90,
      order_number: "00002",
      user_name: "Hasan Sharf",
      Full: 2342,
      captain_name: "Baraa AlJabasene",
      ontime: "1",
      status: "delivered",
      Payment_Method: "cash",
      history: [
        {
          id: 151,
          product_name: "Apple",
          quantity: 1,
          price: 8500,
          discount: 0,
          tax: 200,
          commission: 200,
          total: 8500,
          status: "delivered",
          description: "<p>qwe</p>",
        },
      ],
    },
  ];

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {row.order_number}
          </TableCell>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {row.user.username}
          </TableCell>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {row.total}
          </TableCell>

          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {row.driver ? (
              row.driver.username
            ) : (
              <TaxiAlertIcon className="alert-red" />
            )}
          </TableCell>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {<AccessAlarmIcon className="alert-green" />}
          </TableCell>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            {row.payment_method.name}
          </TableCell>

          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            <ModalMulti
              params={row}
              filter="statusOrder"
              type="order-pop"
              title={row.status}
            />
          </TableCell>
          <TableCell
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          >
            <div className="d-flex justify-content-center align-items-center gap-2">
              <VisibilityIcon
                className="icon-view"
                onClick={(e) => gotToFn({ path: "/order/details", id: row.id })}
              />

              <GpsFixedIcon
                className="icon-socket"
                onClick={(e) => gotToFn({ path: "/order/socket", id: row.id })}
              />

              {row.captain_name ? (
                <AssignmentIndIcon className="order-assign-icon-disable" />
              ) : (
                <ModalMulti
                  params={row.id}
                  filter="assignOrder"
                  type="order-assign"
                />
              )}

              <div className="action-det">
                <ModalDelete text="BigSession" params={row.id} />
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <div className="d-flex w-100 sskk">
                    <h4>{t("Order Product")}</h4>
                  </div>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Product Name")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Quantity")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Price")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Discount")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Tax")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Commission")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Total")}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                      >
                        {t("Status")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.order_details.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.product.name}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.quantity}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.product.price}{" "}
                          <span className="curancy"> sp</span>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.product.discount}%
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.product.tax ? (
                            <Fragment>
                              {" "}
                              {historyRow.product.tax}{" "}
                              <span className="curancy"> sp</span>
                            </Fragment>
                          ) : (
                            0
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.product.commission_value}
                          <span className="curancy"> sp</span>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          {historyRow.price}{" "}
                          <span className="curancy"> sp</span>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign:
                              i18n.language === "ar" ? "right" : "left",
                          }}
                        >
                          <ModalMulti
                            params={historyRow}
                            filter="statusOrder"
                            type="order-pop"
                            title={historyRow.status}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
  //function for export
  function handleExport() {
    const data = newdata.map((row) => [
      row.order_number,
      row.user_name,
      row.Full,
      row.captain_name,
      row.ontime,
      row.Payment_Method,
    ]);
    const historyData =
      newdata.length > 0 &&
      newdata.reduce((acc, row) => {
        row.history.forEach((h) => {
          acc.push([row.name, h.date, h.Fuuull, h.paid, h.Payment_Method]);
        });
        return acc;
      }, []);
    const ws = XLSX.utils.book_new();
    const tableData = [
      ["Name", "Sessions Terminated", "Full Cost", "Been Paid", "Remaining"],
      ...data,
    ];
    const historyHeader = [
      "Name",
      "Date",
      "Full Cost",
      "Been Paid",
      "Payment Method",
    ];
    const historyTableData = [historyHeader, ...historyData];
    const combinedData = [
      ["Data"],
      ...tableData,
      ["History"],
      ...historyTableData,
    ];
    XLSX.utils.book_append_sheet(
      ws,
      XLSX.utils.aoa_to_sheet(combinedData),
      "Combined Data"
    );
    XLSX.writeFile(ws, "table_data.xlsx");
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              <Tooltip title="Export">
                <IconButton onClick={() => handleExport()}>
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Number")}
            </TableCell>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("User Name")}
            </TableCell>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Full Cost")}
            </TableCell>

            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Captain Name")}
            </TableCell>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("On Time")}
            </TableCell>

            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Payment Method")}
            </TableCell>

            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Status")}
            </TableCell>
            <TableCell
              style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
            >
              {t("Actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length > 0 &&
            orders.map((row) => <Row key={row.name} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CollapsibleTable;

import React, { useEffect, useState } from "react";
import "./PopUpDeliveryMessage.scss";

import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  getAllDeliveryMessage,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataDeleveryMessage,
  updateDelveryMessage,
} from "../../../store/DeliveyMessage";
import { notifyError } from "../../../Notification";

function PopUpDeliveryMessage({ handleClose, type, data, title }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let {
    data: deliverymessage,
    loading: loadingSingle,
    error: errorSingle,
    dataCreate,
    dataUpdate,
    loadingBTN,
  } = useSelector((state) => state.deliverymessage);

  useEffect(() => {
    if (dataUpdate) {
      dispatch(getAllDeliveryMessage({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
    }
  }, [dataUpdate]);

  let onsubmitfn = (e) => {
    e.preventDefault();

    let newdata = {
      ...inp,
      _method: "PUT",
    };

    dispatch(
      updateDelveryMessage({
        id: data.row.id,
        data: newdata,
        lang: i18n.language,
      })
    );
  };
  console.log("inp==>", inp);

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            <span>{t("Delivery Message")}</span>
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-lg-12">
              <div className="message-box">
                <input
                  type="text"
                  className="Data"
                  name="before_message"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the message"
                  defaultValue={data.row.before_message}
                  onChange={(e) => getValue(e)}
                  style={{ width: "100%", marginBottom: "15px" }}
                />
                <div className="d-flex justify-content-between align-center">
                  <div className="d-flex align-items-start justify-content-start">
                    <span className="static">{t("from")}</span>
                    <input
                      type="time"
                      className="Data"
                      name="start_time"
                      id="exampleInputPassword1"
                      placeholder="Enter the message"
                      defaultValue={data.row.start_time}
                      onChange={(e) => {
                        getValue(e);
                      }}
                      required
                      style={{ marginBottom: "5px" }}
                    />
                  </div>

                  <div className="d-flex align-items-start justify-content-start">
                    <span className="static">{t("To")}</span>
                    <input
                      type="time"
                      className="Data"
                      name="end_time"
                      id="exampleInputPassword1"
                      placeholder="Enter the message"
                      defaultValue={data.row.end_time}
                      onChange={(e) => getValue(e)}
                      style={{ marginBottom: "5px" }}
                      required
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="Data"
                  name="after_message"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the message"
                  defaultValue={data.row.after_message}
                  onChange={(e) => getValue(e)}
                  style={{ width: "100%", marginTop: "15px" }}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {t("Update")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpDeliveryMessage;

import React, { useEffect, useState } from "react";
import "./MainUser.scss";
import { useTranslation } from "react-i18next";
import Actions from "../../../components/Actions/Actions";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import Datatable from "../../../components/datatable/Datatable";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getAllusers, resetsingleDataUsers } from "../../../store/userslice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { ChangeStatus } from "../../../store/authslice";

function MainUser() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  {
    /**
   let data = [
    {
      id: 1,
      first_name: "Snow",
      last_name: "jeje",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      role: "admin",
      email: "1snow@gmail.com",
      phone: "004961513609853",
      status: true,
    },
    {
      id: 2,
      first_name: "Jamie ",
      last_name: "Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "2snow@gmail.com",
      role: "instructor",
      phone: "+49 157 30900038",
      status: true,
    },
    {
      id: 3,
      first_name: "baraa",
      last_name: "Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "3snow@gmail.com",
      role: "admin",
      phone: "+49 6151 3609850",
      status: false,
    },
    {
      id: 4,
      first_name: "Stark",
      last_name: "tqese",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "4snow@gmail.com",
      role: "admin",
      phone: "0969342178",
      status: true,
    },
    {
      id: 5,
      first_name: "Targaryen",
      last_name: "byrakdar",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "5snow@gmail.com",
      role: "instructor",
      phone: "0969342178",
      status: true,
    },
    {
      id: 6,
      first_name: "Melisandre",
      last_name: "makarm",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "6snow@gmail.com",
      role: "admin",
      phone: 15,
      status: false,
    },
    {
      id: 7,
      first_name: "Clifford",
      last_name: "ssamtt",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "7snow@gmail.com",
      role: "instructor",
      phone: "0996666046",
      status: false,
    },
    {
      id: 8,
      first_name: "Frances",
      last_name: "ssamtt",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "8snow@gmail.com",
      role: "admin",
      phone: "",
      status: true,
    },
    {
      id: 9,
      first_name: "Roxie",
      last_name: "ydren",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      role: "pending",
      phone: "098248157",
      status: true,
    },
    {
      id: 10,
      first_name: "marwan",
      last_name: "tamsha",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      role: "admin",
      phone: "0996666078",
      status: false,
    },
    {
      id: 11,
      first_name: "mayar",
      last_name: "tamsha",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      role: "admin",
      phone: "096848852",
      status: true,
    },
    {
      id: 12,
      first_name: "rami",
      last_name: "salmeh",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      role: "admin",
      phone: "096848852",
      status: true,
    },
  ];

*/
  }

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 100 },
    {
      field: "name",
      headerName: t("Name"),
      width: 270,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.img ? (
              <img className="cellImg" src={params.row.img} alt="avatar" />
            ) : (
              <div className="boxImageChar">
                <span>{params.row.first_name.slice(0, 1)}</span>
              </div>
            )}
            {`${params.row.first_name} ${params.row.last_name}`}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 230,
    },
    {
      field: "phone",
      headerName: t("Phone"),
      sortable: false,
      width: 170,
    },
    {
      field: "role",
      headerName: t("Role"),
      width: 110,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-start gap-2 flex-column">
            <div>{params.row.role}</div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 100,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              defaultChecked={params.row.status === "1" ? true : false}
              onChange={(event) => getStatus(event, params.row.id)}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Actions filter="users" params={params} />
          </div>
        );
      },
    },
  ];

  let getStatus = (e, id) => {
    let data = {
      model_id: id,
      model_class: "user",
      attribute: "status",
      value: e.target.checked ? 1 : 0,
    };
    dispatch(ChangeStatus(data));
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllusers({ lang: i18n.language }));
    dispatch(resetsingleDataUsers(""));
  }, []);

  let { data: users, loading, error } = useSelector((state) => state.users);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Users")}</h3>
          <div className="btn-list">
            <Link to={`/user/add`} className="btn">
              <AddIcon /> {t("Add New")}
            </Link>
            <button className="btn btn-delete">
              <ModalDelete
                filter="users"
                params={users && users}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>

        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={users && users}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainUser;

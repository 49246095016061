import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import {
  createCity,
  createState,
  getAllState,
  resetDataCreateAction,
  resetDataUpdateAction,
  updateState,
} from "../../../store/stateSlice";

function PopUpCity({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
  } = useSelector((state) => state.states);

  let dispatch = useDispatch();

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllState({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreateAction(false));
      dispatch(resetDataUpdateAction(false));
    }
  }, [dataCreate, dataUpdate]);

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "addcity") {
      let newinp = { ...inp, state_id: data.id };
      dispatch(createCity({ data: newinp, lang: i18n.language }));
    }
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  return (
    <Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            <span>
              {t("Add New")} {t("City")}
            </span>

            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        <form class="row" onSubmit={(e) => onsubmitfn(e)}>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleInputSlug">
                {t("City Name")}: <sup className="redstar">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="exampleInputPassword1"
                required
                placeholder="Enter the City name"
                onChange={(e) => getValue(e)}
              />
            </div>
          </div>

          <div className="btn-lest mt-3">
            {loadingBTN ? (
              <button type="text" disabled className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" /> {t("Loading")}...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" />
                {type === "edit" ? t("Edit") : t("Craete")}
              </button>
            )}

            <span onClick={handleClose} className="btn btn-danger-rgba">
              <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
            </span>
          </div>
        </form>
      </Typography>
    </Fragment>
  );
}

export default PopUpCity;

import React, { useEffect } from "react";
import "./ViewProdcut.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Navbar from "../../../components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img1 from "../../../assits/empty-image.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProduct } from "../../../store/product";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
function ViewProdcut() {
  let { name, id } = useParams();
  const [t, i18n] = useTranslation();
  let navigate = useNavigate();
  let goBackFn = () => {
    navigate(-1);
  };

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleProduct({ id: id, lang: i18n.language }));
  }, [id]);

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.products);

  console.log("singleData==>", singleData);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{"Product"}</h3>

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="box-prodcut row">
            <div className="col-md-5">
              <div className="row">
                <h5>{t("Image")}:</h5>
                <div className="box-img">
                  {singleData.image ? (
                    <img src={singleData.image} alt="..." />
                  ) : (
                    <img
                      className="cellImg small-img"
                      src={img1}
                      alt="avatar"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="row">
                <h5>{t("Details")}:</h5>
                <div className="col-md-6">
                  <h6>
                    {t("Name")}: <span>{singleData.name}</span>
                  </h6>
                </div>
                <div className="col-md-6">
                  <h6>
                    {t("Price")}: <span>{singleData.price}</span>
                  </h6>
                </div>
                <div className="col-md-6">
                  <h6>
                    {t("Seller")}: <span>{singleData.seller?.name}</span>
                  </h6>
                </div>

                <div className="col-md-6">
                  <h6>
                    {t("Quantity")}: <span>{singleData.quantity}</span>
                  </h6>
                </div>

                <div className="col-md-6">
                  <h6>
                    {t("Category")}: <span>{singleData.category_id}</span>
                  </h6>
                </div>
                <div className="col-md-6">
                  <h6>
                    {t("Sub Category")}:{" "}
                    <span>{singleData.subcategory_id}</span>
                  </h6>
                </div>

                <div className="col-md-6">
                  <h6>
                    {t("Discount")}: <span>{singleData.discount}</span>
                  </h6>
                </div>

                <div className="col-md-6">
                  <h6>
                    {t("Availability")}:{" "}
                    <span className="Available">
                      {singleData.availability === "1"
                        ? "Available"
                        : "Not Available"}
                    </span>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="row">
                <h5 className="mb-4">{t("Description")}:</h5>
                <div className="col-md-12">
                  <div
                    dangerouslySetInnerHTML={{ __html: singleData.description }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="row">
                <h5 className="mb-4">{t("Variants")}:</h5>
                <div className="row table">
                  <table className="table black-color">
                    <thead>
                      <tr style={{ backgroundColor: "#4F8640" }}>
                        <th>
                          <b style={{ color: "white" }}>Variant </b>
                        </th>
                        <th>
                          <b style={{ color: "white" }}>Value</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {singleData &&
                        singleData.attributes.map((e) => {
                          return (
                            <tr>
                              <td>
                                <div className="black-color">{e.name}</div>
                              </td>
                              <td>
                                <div className="black-color">{e.value}</div>
                              </td>{" "}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewProdcut;

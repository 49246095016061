import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import "./PopUpVariant.scss";
import {
  createAttribute,
  getAllAttribute,
  getSingleAttribute,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataattribute,
  updateAttribute,
} from "../../../store/attribute";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function PopUpVariant({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();

  // let {
  //   data: tags,
  //   loadingBTN,
  //   error,
  //   dataCreate,
  // } = useSelector((state) => state.tags);

  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.attributes);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllAttribute({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDataattribute(""));
    }
  }, [dataCreate, dataUpdate]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let [values, setValues] = useState(
    type === "edit" ? singleData && singleData.values.map((e) => e.value) : []
  );

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleAttribute({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  const [number, setNumber] = useState([]);

  useEffect(() => {
    if (type === "edit") {
      setValues(
        type === "edit"
          ? singleData && singleData.values.map((e) => e.value)
            ? singleData.values.map((e) => e.value)
            : []
          : []
      );
      setNumber(
        type === "edit"
          ? singleData && singleData.values
            ? singleData.values
            : []
          : []
      );
    }
  }, [singleData, type]);

  let handleValuse = (e, index) => {
    let mm = [...values];
    if (values[index] === "" || values[index]) {
      mm[index] = e.target.value;
    } else {
      mm = [...mm, e.target.value];
    }
    setValues(mm);
  };

  let handleAddInput = (e, index) => {
    setNumber((prev) => {
      return [...prev, index];
    });
  };

  console.log("singleData==>", singleData);
  console.log("values==>", values);
  console.log("inp==>", inp);
  console.log("number==>", number);

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      let newdata = {
        ...inp,
        values: [...values],
      };
      console.log("newdata==>", newdata);
      dispatch(createAttribute({ data: newdata, lang: i18n.language }));
    }
    if (type === "edit") {
      let newdata = {
        ...inp,
        values: [...values],
        _method: "PUT",
      };
      console.log("newdata==>", newdata);
      dispatch(
        updateAttribute({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Variant")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Variant")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Variant Name")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the Variant name"
                  defaultValue={singleData && singleData.name}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <span
                className="btn add-new mt-2 mb-2"
                onClick={(e, index) => handleAddInput(e, 1)}
              >
                {t("Add Value")}
              </span>
            </div>

            {number &&
              number.map((e, index) => {
                return (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputSlug">
                        {t("Value Name")} ({index + 1}):{" "}
                        <sup className="redstar">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={index}
                        id="exampleInputPassword1"
                        required
                        placeholder="Enter the Value name"
                        defaultValue={
                          type === "edit"
                            ? singleData?.values[index]?.value
                            : ""
                        }
                        onChange={(e) => handleValuse(e, index)}
                      />
                    </div>
                  </div>
                );
              })}

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpVariant;

import React, { useEffect, useState } from "react";
import "./AddressesUser.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/Navbar/Navbar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUserAddress } from "../../../store/userslice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import LocationPicker from "../../../components/LocationPicker/LocationPicker";

function AddressesUser() {
  
  let { name, id } = useParams();
  const [t, i18n] = useTranslation();
  let navigate = useNavigate();
  let goBackFn = () => {
    navigate(-1);
  };

  let { loadingAddress, errorAdress, dataAddress } = useSelector(
    (state) => state.users
  );

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleUserAddress({ id: id, lang: i18n.language }));
  }, [id]);

  let [selected, onSelectLocation] = useState("");

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{"User Addresses"}</h3>

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>

        {errorAdress ? (
          <ErrorCompo />
        ) : loadingAddress ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="box-favo row">
            {dataAddress &&
              dataAddress.map((e) => {
                return (
                  <div className="col-lg-3">
                    <div className="card">
                      <div className="box-location">
                        <LocationPicker
                          singleData={{
                            latitude: e.latitude,
                            longitude: e.longitude,
                          }}
                          onSelectLocation={onSelectLocation}
                          nochange="true"
                          width="100%"
                          height="150px"
                        />
                      </div>
                      <div className="content">
                        <div className="box-content">
                          <span className="title">{t("Address")} : </span>
                          <span className="title-2">{e.address}</span>
                        </div>
                        <div className="box-content">
                          <span className="title">{t("Area")} : </span>
                          <span className="title-2">{e.area}</span>
                        </div>
                        <div className="box-content">
                          <span className="title">{t("Building")} : </span>
                          <span className="title-2">{e.building}</span>
                        </div>
                        <div className="box-content">
                          <span className="title">
                            {t("Building Number")} :{" "}
                          </span>
                          <span className="title-2">{e.building_number}</span>
                        </div>
                        <div className="box-content">
                          <span className="title">{t("Floor")} : </span>
                          <span className="title-2">{e.floor}</span>
                        </div>
                        <div className="box-content">
                          <span className="title">{t("Street")} : </span>
                          <span className="title-2">{e.street}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddressesUser;

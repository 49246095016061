import React, { useEffect, useState } from "react";
import "./RoleAndPermision.scss";
import Navbar from "../../../components/Navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeletePopUpRole from "../DeletePopUpRole/DeletePopUpRole";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import AddIcon from "@mui/icons-material/Add";
// import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import checkpermation from "../../../Permissions";
import Actions from "../../../components/Actions/Actions";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoles,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataRole,
} from "../../../store/roleSlice";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function RoleAndPermision() {
  let navigation = useNavigate();
  const [t, i18n] = useTranslation();

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllRoles({ lang: i18n.language }));
    dispatch(resetsingleDataRole(""));
    dispatch(resetDataCreate(""));
    dispatch(resetDataUpdate(""));
  }, []);

  // State variable to store selected row IDs
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  // let roles = [
  //   {
  //     id: 1,
  //     name: "admin",
  //   },
  //   {
  //     id: 2,
  //     name: "instructor",
  //   },
  //   {
  //     id: 3,
  //     name: "user",
  //   },
  //   {
  //     id: 5,
  //     name: "company manager",
  //   },
  //   {
  //     id: 6,
  //     name: "bank manager",
  //   },
  //   {
  //     id: 43,
  //     name: "uty",
  //   },
  // ];

  let { data: roles, loading, error } = useSelector((state) => state.roles);

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 150 },
    {
      field: "name",
      headerName: t("Name"),
      width: 750,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 130,
      renderCell: (params) => {
        return (
          <div>
            <Actions filter="Role" params={params} />
          </div>
        );
      },
    },
  ];

  let { dataPermP, loadingPermP, errorPermP } = useSelector(
    (state) => state.roles
  );

  // let Create = checkpermation([{ name: "role.create" }], dataPermP);
  // let Delete = checkpermation([{ name: "role.delete" }], dataPermP);
  let mm = true;
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Roles")}</h3>
          <div className="btn-list">
            <Link to={`/roles-permision/add`} className="btn">
              <AddIcon /> {t("Add New")}
            </Link>
            <button className="btn btn-delete">
              <ModalDelete
                filter="regions"
                params={roles}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={roles ? roles : []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RoleAndPermision;

import axios from "axios";
import { BASE_URL } from "../apiConfig"; // Adjust the path based on your project structure
import { notifyError, notifysuccess } from "../Notification";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export let createuser = createAsyncThunk(
  "users/createuser",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/users`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllusers = createAsyncThunk(
  "users/getusers",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/users`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteuser = createAsyncThunk(
  "users/deleteuser",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/users/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleUser = createAsyncThunk(
  "getSingleUser/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/users/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleUserAddress = createAsyncThunk(
  "getSingleUserAddress/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(
        `${BASE_URL}/user_addresses?user_id=${arg.id}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateuser = createAsyncThunk(
  "updateuser/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(`${BASE_URL}/users/${arg.id}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let GetAllRoles = createAsyncThunk(
  "GetAllRoles/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.get(`${BASE_URL}/roles`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let GetState = createAsyncThunk(
  "GetState/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.get(`${BASE_URL}/states`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let GetCity = createAsyncThunk(
  "GetCity/users",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.get(`${BASE_URL}/cities?state_id=${arg}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.post(`${BASE_URL}/updatePassword`, arg, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let userslice = createSlice({
  name: "users",
  initialState: {
    loadingcountry: false,
    loadingcompanies: false,
    loadingroles: false,
    data: false,
    dataAddress: false,
    error: false,
    showslider: false,
    countries: false,
    companies: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingAddress: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorUser: false,
    errorSingle: false,
    errorAdress: false,
    errorCity: false,
    errorDelete: false,
    city: false,
  },
  reducers: {
    resetAlluserAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataUsers: (state, action) => {
      state.singleData = action.payload;
    },
  },
  extraReducers: {
    //createuser

    [createuser.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createuser.fulfilled]: (state, action) => {
      state.error = false;
      state.data = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createuser.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    //updatePassword

    [updatePassword.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.error = false;
      // state.data = action.payload;
      state.loading = false;
    },
    [updatePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateuser

    [updateuser.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateuser.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateuser.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleUser

    [getSingleUser.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleUser.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleUser.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleUserAddress

    [getSingleUserAddress.pending]: (state, action) => {
      state.loadingAddress = true;
      state.errorAdress = false;
    },
    [getSingleUserAddress.fulfilled]: (state, action) => {
      state.dataAddress = action.payload.data;
      state.errorAdress = false;
      state.loadingAddress = false;
    },
    [getSingleUserAddress.rejected]: (state, action) => {
      state.loadingAddress = false;
      state.errorAdress = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteuser

    [deleteuser.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteuser.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteuser.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllusers

    [getAllusers.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllusers.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllusers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetAllRoles

    [GetAllRoles.pending]: (state, action) => {
      state.loadingroles = true;
      state.error = false;
    },
    [GetAllRoles.fulfilled]: (state, action) => {
      state.loadingroles = false;
      state.error = false;
      state.roles = action.payload;
    },
    [GetAllRoles.rejected]: (state, action) => {
      state.loadingroles = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetState

    [GetState.pending]: (state, action) => {
      state.loadingStates = true;
      state.errorStates = false;
    },
    [GetState.fulfilled]: (state, action) => {
      state.loadingStates = false;
      state.errorStates = false;
      state.states = action.payload;
    },
    [GetState.rejected]: (state, action) => {
      state.loadingStates = false;
      state.errorStates = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetCity

    [GetCity.pending]: (state, action) => {
      state.loadingCity = true;
      state.errorCity = false;
    },
    [GetCity.fulfilled]: (state, action) => {
      state.loadingCity = false;
      state.errorCity = false;
      state.city = action.payload;
    },
    [GetCity.rejected]: (state, action) => {
      state.loadingCity = false;
      state.errorCity = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default userslice.reducer;
export let { resetAlluserAction, resetsingleDataUsers } = userslice.actions;

import React, { useEffect, useState } from "react";
import "./MainGift.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import img1 from "../../../assits/empty-image.jpg";
import Actions from "../../../components/Actions/Actions";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import { getAllGift, resetsingleDataGift } from "../../../store/gift";

function MainGift() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let { data: gifts, loading, errorAll } = useSelector((state) => state.gifts);

  //   {
  //     id: 1,
  //     name: "Rukn Al Den",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  //     status: "0",
  //   },
  //   {
  //     id: 2,
  //     name: "Malki",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",

  //     status: "1",
  //   },
  //   {
  //     id: 3,
  //     name: "Mashro3",
  //     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",

  //     status: "1",
  //   },
  //   {
  //     id: 4,
  //     name: "Muhajren",
  //     img: "",
  //     status: "0",
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "name",
      headerName: t("Name"),
      width: 250,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },

    { field: "quantity", headerName: t("Quantity"), width: 120 },

    { field: "description", headerName: t("Description"), width: 400 },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="gifts" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllGift({ lang: i18n.language }));
    dispatch(resetsingleDataGift(""));
  }, []);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Gifts")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={gifts}
                filter="gifts"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="gifts"
                params={gifts}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={gifts && gifts}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainGift;

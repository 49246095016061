import React, { useEffect, useState } from "react";
import "./MainDeliveryMessage.scss";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import Actions from "../../../components/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllDeliveryMessage,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDataDeleveryMessage,
} from "../../../store/DeliveyMessage";

function MainDeliveryMessage() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDeliveryMessage({ lang: i18n.language }));
    dispatch(resetsingleDataDeleveryMessage(""));
  }, []);

  let {
    data: deliverymessage,
    loading,
    errorAll,
  } = useSelector((state) => state.deliverymessage);

  // let data = [
  //   {
  //     id: 1,
  //     name: "nsbh",
  //   },
  //   {
  //     id: 2,
  //     name: "kash",
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 80 },
    {
      field: "before_message",
      headerName: t("Delivery Message"),
      width: 200,
    },
    {
      field: "start_time",
      headerName: t("Start Time"),
      width: 200,
    },
    {
      field: "end_time",
      headerName: t("End Time"),
      width: 200,
    },
    {
      field: "after_message",
      headerName: t("After Message"),
      width: 200,
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="deliverymessage" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  console.log("deliverymessage==>", deliverymessage);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Delivery Message")}</h3>
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={deliverymessage && deliverymessage}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainDeliveryMessage;

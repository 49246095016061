import React, { useEffect, useState } from "react";
import "./MainState.scss";
import { useTranslation } from "react-i18next";
import Datatable from "../../../components/datatable/Datatable";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import Navbar from "../../../components/Navbar/Navbar";
import Actions from "../../../components/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { getAllState, resetsingleDataState } from "../../../store/stateSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function MainState() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let data = [
    {
      id: 1,
      name: "Rukn Al Den",
    },
    {
      id: 2,
      name: "Malki",
    },
    {
      id: 3,
      name: "Mashro3",
    },
    {
      id: 4,
      name: "Muhajren",
    },
  ];

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 200 },
    {
      field: "name",
      headerName: t("Name"),
      width: 600,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 250,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="states" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllState({ lang: i18n.language }));
    dispatch(resetsingleDataState(""));
  }, []);

  let { data: states, loading, error } = useSelector((state) => state.states);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("States")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={data}
                filter="states"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="states"
                params={data}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={states && states}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainState;

import React, { useEffect, useRef } from "react";
import "./LoadingScreen.scss";
import img1 from "../../assits/Farmy-Splash-Screen-1.gif";

const LoadingScreen = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const playPromise = videoElement.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Autoplay started
        })
        .catch((error) => {
          // Autoplay was prevented
          // You can handle the autoplay prevention here
          console.error("Autoplay prevented:", error);
        });
    }

    return () => {
      // Cleanup if needed
    };
  }, []);

  return (
    <div className="loading-screen">
      <div className="video mt-4">
        <video ref={videoRef} width="100%" height="300" autoPlay muted loop>
          <source src={img1} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default LoadingScreen;

import React, { useEffect, useState } from "react";
import "./PopUpBanners.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createbanners,
  getAllBanners,
  getSingleBanners,
  resetDataCreate,
  resetDataUpdate,
  resetsingleDatabanners,
  updateBanners,
} from "../../../store/banners";
import ResponsiveDateRangePickers from "../../../components/DateRange/DateRange";
import { notifyError } from "../../../Notification";

function PopUpBanners({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  let [dateSelected, setDateSelected] = useState("");
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.banners);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllBanners({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      dispatch(resetsingleDatabanners(""));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleBanners({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      let newDate;
      if (dateSelected) {
        newDate = {
          ...inp,
          start_time: format(dateSelected[0]?.startDate, "yyyy-MM-dd"),
          end_time: format(dateSelected[0]?.endDate, "yyyy-MM-dd"),
        };
      }
      console.log("newDate==>", newDate);
      if (dateSelected === "") {
        notifyError("Chosse Date please");
      }
      if (dateSelected[0]?.startDate === undefined) {
        notifyError("Chosse Start Date please");
      }
      if (dateSelected[0]?.endDate === undefined) {
        notifyError("Chosse End Date please");
      }

      if (dateSelected && inp.type) {
        dispatch(createbanners({ data: newDate, lang: i18n.language }));
      }
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata;
      if (dateSelected) {
        newdata = {
          ...inp,
          start_time: format(dateSelected[0]?.startDate, "yyyy-MM-dd"),
          end_time: format(dateSelected[0]?.endDate, "yyyy-MM-dd"),
          _method: "PUT",
        };
      } else {
        newdata = {
          ...inp,
          _method: "PUT",
        };
      }

      dispatch(
        updateBanners({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      );
    }
  };

  let handelImage = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.files[0] };
    });
  };

  console.log("singleData==>", singleData);
  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Banner")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Banner")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="uplaod-image-group mt-3">
              <div className="form-group">
                <label className="text-dark" htmlFor="exampleInputSlug">
                  {t("Upload Image")}:<sup className="text-danger">*</sup>
                </label>
                <small className="text-muted">
                  <i className="fa fa-question-circle" />
                  Recommended size (410 x 410px)
                </small>

                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      onChange={(e) => {
                        handelImage(e);
                      }}
                      name="image"
                      className="custom-file-input"
                      id="image"
                    />
                    <label className="custom-file-label" htmlFor="image">
                      {(inp.image && inp.image.name) || t("Choose File")}
                    </label>
                  </div>
                  <label className="custom-upload" htmlFor="image">
                    {t("Upload")}
                  </label>
                </div>
                {inp.image ? (
                  <img
                    src={URL.createObjectURL(inp.image)}
                    alt="...."
                    width="150"
                    height="150"
                  />
                ) : (
                  singleData &&
                  singleData.image && (
                    <img
                      src={
                        singleData.image instanceof File
                          ? URL.createObjectURL(singleData.image)
                          : singleData.image
                      }
                      alt="...."
                      width="150"
                      height="150"
                    />
                  )
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Banner Link")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="link"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter the Banner link"
                  defaultValue={type === "edit" ? singleData.link : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label className="text-dark" htmlFor="fname">
                  {t("Type")}: <sup className="redstar">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getValue(e)}
                  name="type"
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.type
                      : "select Value Please"
                  }
                >
                  <option
                    disabled={true}
                    value={
                      type === "edit"
                        ? singleData && singleData.type
                        : "select Value Please"
                    }
                  >
                    {type === "edit"
                      ? singleData && singleData.type
                      : "select Value Please"}
                  </option>
                  <option value={"section1"}>{t("section1")}</option>
                  <option value={"section2"}>{t("section2")}</option>
                </select>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Banner Date")}: <sup className="redstar">*</sup>
                </label>
                <ResponsiveDateRangePickers
                  setDateSelected={setDateSelected}
                  singleData={singleData}
                  type={type}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpBanners;

import { Link, useLocation, useNavigate } from "react-router-dom";
import "./register.scss";
import img1 from "../../assits/logo_app.svg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { islogged, regidata } from "../../store/authslice.js";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { notifyError } from "../../Notification.js";
import { useTranslation } from "react-i18next";
function Register() {
  let dispatch = useDispatch();
  let { data, loading, error } = useSelector(
    (state) => state.persistTheme.auth
  );

  let [recap, setReCap] = useState("");
  let [inp, setinp] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    password_confirmation: "",
    mobile: "",
    roles_id: ["3"],
    term: 0,
  });

  const handleCheckboxChange = (e) => {
    // Update inp.term based on whether the checkbox is checked
    setinp((prev) => {
      return { ...prev, term: e.target.checked ? 1 : 0 };
    });
  };

  let [focusemail, setFocusEmail] = useState(false);
  let [focusPassword, setfocusPassword] = useState(false);
  let [focuspassword_confirmation, setfocuspassword_confirmation] =
    useState(false);
  let [focusfname, setfoucefname] = useState(false);
  let [focuslast_nmae, setfocuslname] = useState(false);

  let bluefn = (e) => {
    if (e === "email") {
      setFocusEmail(true);
    }
    if (e === "password") {
      setfocusPassword(true);
    }
    if (e === "password_confirmation") {
      setfocuspassword_confirmation(true);
    }
    if (e === "fname") {
      setfoucefname(true);
    }
    if (e === "lname") {
      setfocuslname(true);
    }
  };

  let onChange = (e) => {
    setReCap(e);
  };
  let getdata = (e) => {
    setinp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let navigate = useNavigate();
  useEffect(() => {
    // This effect will run whenever `data` changes
    if (data) {
      navigate("/"); // Navigate to the desired route when `data` becomes true
    }
  }, [data, navigate]); // Watch for changes in `data` and `navigate`

  let getvalue = (e) => {
    e.preventDefault();

    if (inp.mobile.length < 10) {
      notifyError("The mobile phone field must be between 10 and 15 numbers");
    }
    if (!recap) {
      notifyError(
        "Please click on the verification option to make sure you are not a robot"
      );
    }
    if (
      inp.email &&
      inp.password &&
      inp.email &&
      inp.fname &&
      inp.lname &&
      recap &&
      inp.mobile.length >= 10 &&
      inp.password_confirmation
    ) {
      dispatch(regidata(inp));
      if (data) {
        navigate("/");
      }
    }
  };
  const [t, i18n] = useTranslation();

  return (
    <div className="register">
      <form onSubmit={(e) => getvalue(e)}>
        <div className="box-login row">
          <div className="logo col-lg-12">
            <img src={img1} alt="..." className="img-fluid" />
            <h2>{t("Farmy")}</h2>
          </div>
          <div className="col-lg-12 inps">
            <div className="sign-up-inp">
              <div className="inp-icon">
                <PersonOutlineIcon className="icon" />
                <input
                  name="fname"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter your FirstName"
                  required
                  pattern="^[A-Za-z0-9ا-ي ]{3,16}$"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  onBlur={() => {
                    bluefn("fname");
                  }}
                  focused={focusfname.toString()}
                />
                <span>
                  FirstName should be 3-16 characters and shouldn't include any
                  special characters
                </span>
              </div>

              <div className="inp-icon">
                <PersonOutlineIcon className="icon" />
                <input
                  type="text"
                  required
                  pattern="^[A-Za-z0-9ا-ي]{2,16}$"
                  name="lname"
                  placeholder="Enter Your LastName"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  onBlur={() => {
                    bluefn("lname");
                  }}
                  focused={focuslast_nmae.toString()}
                />
                <span>
                  LastName should be 3-16 characters and shouldn't include any
                  special characters
                </span>
              </div>
              <div className="inp-icon">
                <MailOutlineIcon className="icon" />
                <input
                  name="email"
                  type="email"
                  autoComplete="off"
                  placeholder="Enter your Email"
                  required
                  onChange={(e) => {
                    getdata(e);
                  }}
                  onBlur={() => {
                    bluefn("email");
                  }}
                  focused={focusemail.toString()}
                />
                <span>put your email please with @</span>
              </div>
              <div className="inp-icon">
                <LockIcon className="icon" />
                <input
                  name="password"
                  type="password"
                  autoComplete="off"
                  placeholder="Enter your password"
                  required
                  pattern="^(?=.*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9])(?=.*[a-zA-Z].*[a-zA-Z]).{6,}$"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  onBlur={() => {
                    bluefn("password");
                  }}
                  focused={focusPassword.toString()}
                />
                <span>
                  Password must have at least 6 numbers, 2 characters, and 1
                  special character
                </span>
              </div>
              <div className="inp-icon">
                <LockIcon className="icon" />
                <input
                  type="password"
                  autoComplete="off"
                  placeholder="COnfirm password"
                  name="password_confirmation"
                  required
                  pattern="^(?=.*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9])(?=.*[a-zA-Z].*[a-zA-Z]).{6,}$"
                  onChange={(e) => {
                    getdata(e);
                  }}
                  onBlur={() => {
                    bluefn("password_confirmation");
                  }}
                  focused={focuspassword_confirmation.toString()}
                />
                <span>
                  Password must have at least 6 numbers, 2 characters, and 1
                  special character and same password
                </span>
              </div>

              <div className="inp-icon">
                <LockIcon className="icon" />
                <input
                  type="number"
                  placeholder="Mobile"
                  name="mobile"
                  required
                  onChange={(e) => {
                    getdata(e);
                  }}
                />
              </div>
            </div>

            <div className="text-center">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                security="6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
                onChange={onChange}
              />
            </div>

            <div className="text-re">
              <div className="check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="remember"
                  id="remember"
                  onChange={handleCheckboxChange}
                />
                <p style={{ fontWeight: "bold" }}>
                  I agree to Terms & Condition , Privacy Policy.
                </p>
              </div>
            </div>

            {loading ? (
              <button className="btn " disabled>
                Loading..
              </button>
            ) : (
              <button className="btn " disabled={!recap}>
                Signup
              </button>
            )}

            <div className="sign-up-ask text-center">
              <p>
                Already have an account?
                <span>
                  <Link className="link" to={`/login`}>
                    Login
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;

import React, { useEffect, useState } from "react";
import "./MainDeliveryAttrebute.scss";
import Actions from "../../../components/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../../components/datatable/Datatable";
import {
  getAllDeviryAttrebute,
  resetsingleDataDeveryAttrebute,
} from "../../../store/deleviryattrebute";

function MainDeliveryAttrebute() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDeviryAttrebute({ lang: i18n.language }));
    dispatch(resetsingleDataDeveryAttrebute(""));
  }, []);

  let {
    data: deliveryattrebute,
    loading,
    errorAll,
  } = useSelector((state) => state.deliveryattrebute);

  // let data = [
  //   {
  //     id: 1,
  //     name: "nsbh",
  //   },
  //   {
  //     id: 2,
  //     name: "kash",
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 200 },
    {
      field: "name",
      headerName: t("Delivery Attrebute"),
      width: 550,
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="deliveryattrebute" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Delivery Attrebute")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={deliveryattrebute}
                filter="deliveryattrebute"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="deliveryattrebute"
                params={deliveryattrebute}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={deliveryattrebute && deliveryattrebute}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainDeliveryAttrebute;

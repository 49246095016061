import React, { useEffect, useState } from "react";
import "./MainSellers.scss";
import Datatable from "../../../components/datatable/Datatable";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import Actions from "../../../components/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { getAllSellers, resetsingleDataSeller } from "../../../store/seller";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function MainSellers() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let { data: sellers, loading, error } = useSelector((state) => state.sellers);


  //   {
  //     id: 1,
  //     name: "answer",
  //     status: "0",
  //     discount_status: "0",
  //     discount: 0,
  //     type: "internal",
  //     location: "Rukn Al Den",
  //   },
  //   {
  //     id: 2,
  //     name: "wassem",
  //     status: "1",
  //     discount_status: "0",
  //     discount: 0,
  //     type: "external",
  //     location: "Muhajren",
  //   },
  //   {
  //     id: 3,
  //     name: "baraa",
  //     status: "1",
  //     discount_status: "0",
  //     discount: 0,
  //     type: "external",
  //     location: "Malky",
  //   },
  //   {
  //     id: 4,
  //     name: "mayar",
  //     status: "0",
  //     discount_status: "1",
  //     discount: 12,
  //     type: "external",
  //     location: "zamalka",
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 150 },
    {
      field: "name",
      headerName: t("Sellers"),
      width: 250,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.name}`}</div>;
      },
    },
    {
      field: "type",
      headerName: t("Type"),
      width: 150,
    },
    {
      field: "area",
      headerName: t("Area"),
      width: 250,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.area.name}`}</div>;
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 180,
      renderCell: (params) => {
        return (
          <div className="status">
            <Switch
              {...label}
              defaultChecked={params.row.status === "1" ? true : false}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="Sellers" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };


  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSellers({ lang: i18n.language }));
    dispatch(resetsingleDataSeller(""));
  }, []);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Sellers")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={sellers}
                filter="Sellers"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="Sellers"
                params={sellers}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {error ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={sellers && sellers}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainSellers;

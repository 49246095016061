import React, { useEffect, useState } from "react";
import "./PopUpDeliveyAttrebute.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  createCoupon,
  getAllCoupons,
  getAllCouponsTypes,
  resetAllCouponsAction,
  resetDataCreate,
  resetDataUpdate,
  setSingleData,
  updateCoupon,
} from "../../../store/coupons";
function PopUpCoupon({ handleClose, type, data }) {
  console.log("data", data);
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  let {
    data: coupons,
    loadingBTN,
    types,
    error,
    dataUpdate,
    dataCreate,
    typesLoading,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.Coupons);

  useEffect(() => {
    dispatch(getAllCouponsTypes({ lang: i18n.language }));
  }, [type]);

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      handleClose();
      dispatch(getAllCoupons({ lang: i18n.language }));
      dispatch(resetDataCreate(false));
      dispatch(resetDataUpdate(false));
      // dispatch(resetAllCouponsAction(""));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(setSingleData(coupons?.find((el) => el.id === data.id)));
      setInp({
        coupon_type_id: singleData?.coupon_type?.id,
        description: singleData?.description,
        price: singleData?.price,
        value: singleData?.value,
      });
    }
  }, [singleData]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    console.log("inp", inp);
    if (type === "add") {
      dispatch(createCoupon({ data: inp, lang: i18n.language }));
    }
    if (type === "edit") {
      dispatch(
        updateCoupon({
          id: singleData?.id,
          ...inp,
          // _method: "PUT",
        })
      );
    }
  };
  console.log("singleData", singleData);
  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("Coupon")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("Coupon")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="exampleInputSlug">
                  {t("Coupon type")}: <sup className="redstar">*</sup>
                </label>
                <select
                  style={{
                    borderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getValue(e)}
                  name="coupon_type_id"
                >
                  <option value="">
                    {type === "edit"
                      ? singleData?.coupon_type?.name
                      : "Select Value Please"}
                  </option>

                  {typesLoading ? (
                    <option disabled>Loading...</option>
                  ) : (
                    Array.isArray(types) &&
                    types.map((coupon) => (
                      <option key={coupon.id} value={coupon.id}>
                        {coupon.name}
                      </option>
                    ))
                  )}
                </select>

                <label htmlFor="exampleInputSlug">
                  {t("Coupon price")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter coupon price"
                  defaultValue={type === "edit" ? singleData.price : ""}
                  onChange={(e) => getValue(e)}
                />
                <label htmlFor="exampleInputSlug">
                  {t("Coupon value")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="value"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter coupon value"
                  defaultValue={type === "edit" ? singleData.value : ""}
                  onChange={(e) => getValue(e)}
                />
                <label htmlFor="exampleInputSlug">
                  {t("Coupon discription")}: <sup className="redstar">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  id="exampleInputPassword1"
                  required
                  placeholder="Enter coupon description"
                  defaultValue={type === "edit" ? singleData.description : ""}
                  onChange={(e) => getValue(e)}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpCoupon;

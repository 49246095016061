import React, { useEffect } from "react";
import "./ViewCaptin.scss";
import { useTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import RadarIcon from "@mui/icons-material/Radar";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import Navbar from "../../../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCaptain } from "../../../store/captainSlice";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";

function ViewCaptin() {
  const [t, i18n] = useTranslation();
  let navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let goBackFn = () => {
    navigate(-1);
  };
  let { name, id } = useParams();
  let {
    states,
    loadingBTN,
    loadingSingle,
    singleData,
    errorSingle,
    loadingStates,
    errorStates,
    loadingCity,
    city,
    errorCity,
  } = useSelector((state) => state.captains);

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleCaptain({ id: id, lang: i18n.language }));
  }, [id]);

  console.log("singleData==>", singleData);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box action-box">
        <div className="main-header add-header">
          <h3>{t("Captains")}</h3>

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>
        {/*
         <div className="action ms-3">
          <Link to="/user/favourite/1" className="btn fav">
            {t("favourite")}
          </Link>
          <span className="btn bat">{t("Points")}</span>
        </div>
      */}
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="box-view row">
            <div className="col-md-3">
              <div className="box-image">
                <img
                  src="https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-md-9 row">
              <div className="col-md-6">
                <div className="icon-box">
                  <PersonIcon />
                  <span>: {singleData && singleData.name}</span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="icon-box">
                  <EmailIcon />
                  <span>: {singleData && singleData.email}</span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="icon-box">
                  <LocalPhoneIcon />
                  <span>: {singleData && singleData.phone}</span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="icon-box">
                  <RadarIcon />
                  <span>
                    :{" "}
                    {singleData && singleData.role_id
                      ? singleData.role_id
                      : "admin"}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icon-box">
                  <FmdGoodIcon />
                  <span>: {singleData && singleData.city?.state?.name}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icon-box">
                  <FmdGoodIcon />
                  <span>: {singleData && singleData.city?.name}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewCaptin;

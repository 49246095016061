import * as React from "react";
import { useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";

export default function ResponsiveDateRangePickers({
  setDateSelected,
  singleData,
  type,
}) {
  const [opendate, setOpendate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (type === "edit") {
      // Set default values for DateRange based on start_time and end_time in singleData
      setDate([
        {
          startDate: new Date(singleData.start_time),
          endDate: new Date(singleData.end_time),
          key: "selection",
        },
      ]);
    }
  }, [type, singleData]);

  const formattedDate =
    date[0].startDate &&
    date[0].endDate &&
    `Start Date: ${format(
      date[0].startDate,
      "yyyy-MM-dd"
    )} / End Date: ${format(date[0].endDate, "yyyy-MM-dd")}`;

  return (
    <div>
      <span
        onClick={() => {
          setOpendate(!opendate);
        }}
        className="mb-3 form-control"
      >
        {formattedDate || "Click to select dates"}
      </span>
      {opendate && (
        <div>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setDate([item.selection]);
              setDateSelected([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={date}
            rangeColors={["#4F8640"]}
          />

          <CloseIcon
            onClick={() => {
              setOpendate(!opendate);
            }}
            style={{ cursor: "pointer" }}
            className="ms-3"
          />
        </div>
      )}
    </div>
  );
}

import React from "react";
import "./FavoUser.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Navbar from "../../../components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img1 from "../../../assits/kosa.jpg";
import img2 from "../../../assits/b6a6a.jpg";
import img3 from "../../../assits/flyglh.jpg";
import img4 from "../../../assits/empty-image.jpg";
function  FavoUser() {
  let { name, id } = useParams();
  const [t, i18n] = useTranslation();
  let navigate = useNavigate();
  let goBackFn = () => {
    navigate(-1);
  };
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{"User Favourite"}</h3>

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>

        <div className="box-favo row">
          <div className="col-lg-3">
            <div className="card">
              <div className="img-box">
                <img src={img2} alt="..." />
              </div>
              <div className="box-title">
                <div className="head">
                  <h5>Zora Corn</h5>
                  <span className="price">2300 SYP</span>
                </div>
                <div className="detils">
                  <p>{t("plants")}</p>
                  <p>{t("Grass")}</p>
                </div>
                <div className="description">
                  <p>-Doctorate in Islamic Law</p>
                  <p>
                    -Academic director and lecturer at various universities and
                    institutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <div className="img-box">
                <img src={img1} alt="..." />
              </div>
              <div className="box-title">
                <div className="head">
                  <h5>Zora Corn</h5>
                  <span className="price">2300 SYP</span>
                </div>
                <div className="detils">
                  <p>{t("plants")}</p>
                  <p>{t("Grass")}</p>
                </div>
                <div className="description">
                  <p>-Doctorate in Islamic Law</p>
                  <p>
                    -Academic director and lecturer at various universities and
                    institutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <div className="img-box">
                <img src={img4} alt="..." style={{ objectFit: "contain" }} />
              </div>
              <div className="box-title">
                <div className="head">
                  <h5>Zora Corn</h5>
                  <span className="price">2300 SYP</span>
                </div>
                <div className="detils">
                  <p>{t("plants")}</p>
                  <p>{t("Grass")}</p>
                </div>
                <div className="description">
                  <p>-Doctorate in Islamic Law</p>
                  <p>
                    -Academic director and lecturer at various universities and
                    institutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <div className="img-box">
                <img src={img3} alt="..." />
              </div>
              <div className="box-title">
                <div className="head">
                  <h5>Zora Corn</h5>
                  <span className="price">2300 SYP</span>
                </div>
                <div className="detils">
                  <p>{t("plants")}</p>
                  <p>{t("Grass")}</p>
                </div>
                <div className="description">
                  <p>-Doctorate in Islamic Law</p>
                  <p>
                    -Academic director and lecturer at various universities and
                    institutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FavoUser;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createCaptain = createAsyncThunk(
  "captain/createCaptain",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/drivers`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllCaptain = createAsyncThunk(
  "captain/getAllCaptain",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/drivers`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteCaptain = createAsyncThunk(
  "captain/deleteCaptain",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/drivers/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleCaptain = createAsyncThunk(
  "captain/getSingleCaptain",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/drivers/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateCaptain = createAsyncThunk(
  "captain/updateCaptain",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(`${BASE_URL}/drivers/${arg.id}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let GetState = createAsyncThunk(
  "GetState/captian",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.get(`${BASE_URL}/states`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let GetCity = createAsyncThunk(
  "GetCity/captian",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.get(`${BASE_URL}/cities?state_id=${arg}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let captainslice = createSlice({
  name: "captain",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
    errorCity: false,
    city: false,
  },
  reducers: {
    resetAllCaptainAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataCaptain: (state, action) => {
      state.singleData = action.payload;
    },
  },
  extraReducers: {
    //createCaptain

    [createCaptain.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createCaptain.fulfilled]: (state, action) => {
      state.error = false;
      state.data = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createCaptain.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateCaptain

    [updateCaptain.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateCaptain.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateCaptain.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleCaptain

    [getSingleCaptain.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleCaptain.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleCaptain.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteCaptain

    [deleteCaptain.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteCaptain.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteCaptain.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllCaptain

    [getAllCaptain.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllCaptain.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllCaptain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetState

    [GetState.pending]: (state, action) => {
      state.loadingStates = true;
      state.errorStates = false;
    },
    [GetState.fulfilled]: (state, action) => {
      state.loadingStates = false;
      state.errorStates = false;
      state.states = action.payload;
    },
    [GetState.rejected]: (state, action) => {
      state.loadingStates = false;
      state.errorStates = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //GetCity

    [GetCity.pending]: (state, action) => {
      state.loadingCity = true;
      state.errorCity = false;
    },
    [GetCity.fulfilled]: (state, action) => {
      state.loadingCity = false;
      state.errorCity = false;
      state.city = action.payload;
    },
    [GetCity.rejected]: (state, action) => {
      state.loadingCity = false;
      state.errorCity = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default captainslice.reducer;
export let { resetAllCaptainAction, resetsingleDataCaptain } =
  captainslice.actions;

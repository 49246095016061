import React, { useEffect, useState } from "react";
import "./MainBanners.scss";
import Datatable from "../../../components/datatable/Datatable";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllBanners, resetsingleDatabanners } from "../../../store/banners";
import Actions from "../../../components/Actions/Actions";
import Navbar from "../../../components/Navbar/Navbar";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import img1 from "../../../assits/empty-image.jpg";

function MainBanners() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBanners({ lang: i18n.language }));
    dispatch(resetsingleDatabanners(""));
  }, []);

  let {
    data: Banners,
    loading,
    errorAll,
  } = useSelector((state) => state.banners);

  // let data = [
  //   {
  //     id: 1,
  //     name: "nsbh",
  //   },
  //   {
  //     id: 2,
  //     name: "kash",
  //   },
  // ];
  const userColumns = [
    { field: "id", headerName: t("ID"), width: 90 },
    {
      field: "name",
      headerName: t("Image"),
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.image ? (
              <img className="cellImg" src={params.row.image} alt="avatar" />
            ) : (
              <div className="boxImageEmpty">
                <img className="cellImg" src={img1} alt="avatar" />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: t("Type"),
      width: 120,
    },
    {
      field: "link",
      headerName: t("Link"),
      width: 220,
    },
    {
      field: "start_time",
      headerName: t("Start Time"),
      width: 180,
    },
    {
      field: "end_time",
      headerName: t("End Time"),
      width: 180,
    },

    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="actions-box">
            <Actions filter="Banners" params={params} />
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Banners")}</h3>
          <div className="btn-list">
            <button className="btn">
              <ModalMulti
                data={Banners}
                filter="Banners"
                type="add"
                title={"Add New"}
              />
            </button>
            <button className="btn btn-delete">
              <ModalDelete
                filter="Banners"
                params={Banners}
                type="all"
                selectedRowIds={selectedRowIds}
              />
            </button>
          </div>
        </div>
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={Banners && Banners}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainBanners;

import React, { Fragment, useEffect, useState } from "react";
import "./InputEditBox.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCity,
  deleteState,
  getAllState,
  getSingleState,
  resetDataCreateAction,
  resetDataUpdateAction,
  updateCity,
  updateState,
} from "../../store/stateSlice";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../useClickOutside";

function InputEditBox({ e, type, data }) {
  let [toggle, setToggle] = useState(true);
  let [Deletetoggle, setDeleteToggle] = useState(false);
  let [inp, setInp] = useState({ name: e.name });
  const [t, i18n] = useTranslation();

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.states);

  let dispatch = useDispatch();

  useEffect(() => {
    if (type === "state") {
      if (dataCreate || dataUpdate) {
        dispatch(getAllState({ lang: i18n.language }));
        dispatch(resetDataCreateAction(false));
        dispatch(resetDataUpdateAction(false));
      }
    }
  }, [dataCreate, dataUpdate]);

  let onsubmitfn = (z) => {
    z.preventDefault();
    if (type === "state") {
      const updatedData = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateState({
          id: e.id,
          data: updatedData,
          lang: i18n.language,
        })
      );
    } else {
      const updatedData = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateCity({
          id: e.id,
          data: updatedData,
          lang: i18n.language,
        })
      );
    }
  };

  let domNode = useClickOutside(() => {
    setToggle(true);
  });

  let DeleteFucntion = (z) => {
    z.preventDefault();
    if (type === "state") {
      const updatedData = {
        _method: "DELETE",
      };
      dispatch(
        deleteState({
          id: e.id,
          data: updatedData,
          lang: i18n.language,
        })
      );
    } else {
      const updatedData = {
        _method: "DELETE",
      };
      dispatch(
        deleteCity({
          id: e.id,
          data: updatedData,
          lang: i18n.language,
        })
      );
      dispatch(getSingleState({ id: data.id, lang: i18n.language }));
    }
  };

  return (
    <form>
      <div className="input-edit-box" ref={domNode}>
        {toggle ? (
          <p>{inp.name}</p>
        ) : (
          <input
            type="text"
            name="name"
            id="exampleInputPassword1"
            required
            placeholder="Enter the City name"
            defaultValue={inp.name}
            onChange={(e) => getValue(e)}
          />
        )}
        <div className="icons">
          {toggle ? (
            <Fragment>
              <EditIcon
                className="edit-icon"
                onClick={(e) => {
                  setToggle(!toggle);
                }}
              />
              <DeleteIcon
                className="edit-delete"
                onClick={(e) => setDeleteToggle(true)}
              />
            </Fragment>
          ) : (
            <SaveIcon
              className="edit-save"
              onClick={(e) => {
                setToggle(!toggle);
                onsubmitfn(e);
              }}
            />
          )}
        </div>
      </div>

      {Deletetoggle && (
        <div className="pop-up-delete">
          <h4>{t("Are You Sure ?")}</h4>
          <p className="message mb-4">
            {t("Do you really want to delete")}
            {t("This process cannot be undone.")}
          </p>
          <div className="btnleist-delte">
            <button
              onClick={(e) => {
                setDeleteToggle(false);
                DeleteFucntion(e);
              }}
              className="btn btn-y"
            >
              {t("Yes")}
            </button>
            <span onClick={() => setDeleteToggle(false)} className="btn btn-n">
              {t("No")}
            </span>
          </div>
        </div>
      )}
    </form>
  );
}

export default InputEditBox;

import "./login.scss";
import img1 from "../../assits/logo_app.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { islogged, logdata } from "../../store/authslice";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { notifyError } from "../../Notification";
import { useTranslation } from "react-i18next";

function Login({ path }) {
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  // let [recap, setReCap] = useState("");
  let [inp, setinp] = useState({
    email: "",
    password: "",
  });

  let [focusemail, setFocusEmail] = useState(false);
  let [focusPassword, setfocusPassword] = useState(false);

  let bluefn = (e) => {
    if (e === "email") {
      setFocusEmail(true);
    }
    if (e === "password") {
      setfocusPassword(true);
    }
  };

  let getdata = (e) => {
    setinp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  let { data, loading, error } = useSelector(
    (state) => state.persistTheme.auth
  );
  let navigate = useNavigate();
  useEffect(() => {
    // This effect will run whenever `data` changes
    if (data) {
      navigate("/"); // Navigate to the desired route when `data` becomes true
    }
  }, [data, navigate]); // Watch for changes in `data` and `navigate`

  let location = useLocation();
  let getvalue = (e) => {
    e.preventDefault();
    // if (!recap) {
    //   notifyError(
    //     "Please click on the verification option to make sure you are not a robot"
    //   );
    // }
    if (inp.email && inp.password ) {
      if (location.state?.data) {
        dispatch(logdata(inp));
        navigate(`/course/${location.state?.data}`);
      }
      dispatch(logdata(inp));
      if (data) {
        navigate(`${path ? path : "/"}`);
      }
      // window.location.reload();
    }
  };

  function onChange(value) {
    // setReCap(value);

    // Set the reCAPTCHA value in your state
    // setinp((prev) => {
    //   return { ...prev, recaptcha: value };
    // });
  }
  return (
    <div className="login">
      <form onSubmit={(e) => getvalue(e)}>
        <div className="box-login row">
          <div className="logo col-lg-12">
            <img src={img1} alt="..." className="img-fluid" />
            <h2>{t("Farmy")}</h2>
          </div>
          <div className="col-lg-12 inps">
            <div className="inp-icon">
              <MailOutlineIcon className="icon" />
              <input
                name="email"
                type="email"
                autoComplete="off"
                placeholder="Enter your Email.."
                required
                onChange={(e) => {
                  getdata(e);
                }}
                onBlur={() => {
                  bluefn("email");
                }}
                focused={focusemail.toString()}
              />
              <span>put your email please with @</span>
            </div>

            <div className="inp-icon">
              <LockIcon className="icon" />
              <input
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Enter your password.."
                required
                onChange={(e) => {
                  getdata(e);
                }}
                onBlur={() => {
                  bluefn("password");
                }}
                focused={focusPassword.toString()}
              />
              {/*<span>
                Password must have at least 6 numbers, 2 characters, and 1
                special character
              </span>*/}
            </div>

            {/* <div className="text-center">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                security="6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
                onChange={onChange}
              />
            </div> */}

            {/*
          <div className="text-re">
          <div className="check">
            <input
              className="form-check-input"
              type="checkbox"
              name="remember"
              id="remember"
            />
            <p>Remember Me</p>
          </div>
          <Link to={`/reset-password`}>
            <p>Forgot Password</p>
          </Link>
        </div>
        */}
            {error && (
              <p style={{ color: "#970000", fontSize: "17px" }}>
                {error.data.message}
              </p>
            )}
            {loading ? (
              <button className="btn " disabled>
                {t("loading")}...
              </button>
            ) : (
              <button className="btn ">
                {t("Login")}
              </button>
            )}

            {/*
          <div className="sign-up-ask text-center">
          <p>
            {t("Do not have an account")}?{" "}
            <span>
              <Link className="link" to={`/register`}>
                {t("Signup")}
              </Link>
            </span>
          </p>
        </div>
        */}
            <hr />
            <div className="policy-ask text-center">
              <p>
                By signing up, you agree to our Terms & Condition , Privacy
                Policy.{" "}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;

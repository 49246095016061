import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createAttribute = createAsyncThunk(
  "attribute/createAttribute",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/attributes`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllAttribute = createAsyncThunk(
  "attribute/getAllAttribute",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/attributes`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getValueAttribute = createAsyncThunk(
  "attribute/getValueAttribute",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/attribute_values?attribute_id=${arg.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  "attribute/deleteAttribute",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/attributes/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleAttribute = createAsyncThunk(
  "attribute/getSingleAttribute",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/attributes/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateAttribute = createAsyncThunk(
  "attribute/updateAttribute",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/attributes/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let attributeslice = createSlice({
  name: "attribute",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    values: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    loadingValue: false,
    errorValue: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
  },
  reducers: {
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataattribute: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createAttribute

    [createAttribute.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createAttribute.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createAttribute.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateAttribute

    [updateAttribute.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateAttribute.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateAttribute.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleAttribute

    [getSingleAttribute.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleAttribute.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleAttribute.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteAttribute

    [deleteAttribute.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteAttribute.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteAttribute.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllAttribute

    [getAllAttribute.pending]: (state, action) => {
      state.loading = true;

      state.error = false;
      state.data = false;
    },
    [getAllAttribute.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllAttribute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getValueAttribute

    [getValueAttribute.pending]: (state, action) => {
      state.loadingValue = true;
      state.error = false;
      state.values = false;
    },
    [getValueAttribute.fulfilled]: (state, action) => {
      state.errorValue = false;
      state.loadingValue = false;
      state.values = action.payload;
    },
    [getValueAttribute.rejected]: (state, action) => {
      state.loadingValue = false;
      state.errorValue = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default attributeslice.reducer;
export let {
  resetAllSellerAction,
  resetsingleDataattribute,
  resetDataCreate,
  resetDataUpdate,
} = attributeslice.actions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let createSubCategory = createAsyncThunk(
  "subCategory/createSubCategory",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/sub_categories`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllSubCategory = createAsyncThunk(
  "subCategory/getAllSubCategory",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/sub_categories`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllSubCategoryByCat = createAsyncThunk(
  "subCategory/getAllSubCategoryByCat",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/sub_categories?categoryId=${arg.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "subCategory/deleteSubCategory",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/sub_categories/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleSubCategory = createAsyncThunk(
  "subCategory/getSingleSubCategory",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/sub_categories/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateSubCategory = createAsyncThunk(
  "subCategory/updateSubCategory",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/sub_categories/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let subcategoryslice = createSlice({
  name: "category",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
  },
  reducers: {
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataSubCategory: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createSubCategory

    [createSubCategory.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createSubCategory.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createSubCategory.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateSubCategory

    [updateSubCategory.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateSubCategory.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.error = false;
      state.dataUpdate = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateSubCategory.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleSubCategory

    [getSingleSubCategory.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleSubCategory.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleSubCategory.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteSubCategory

    [deleteSubCategory.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteSubCategory.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteSubCategory.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllSubCategory

    [getAllSubCategory.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllSubCategory.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllSubCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllSubCategoryByCat

    [getAllSubCategoryByCat.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.data = false;
    },
    [getAllSubCategoryByCat.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    },
    [getAllSubCategoryByCat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default subcategoryslice.reducer;
export let {
  resetAllSellerAction,
  resetsingleDataSubCategory,
  resetDataCreate,
  resetDataUpdate,
} = subcategoryslice.actions;

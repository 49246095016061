import "./PopUpState.scss";
import React, { Fragment, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import {
  createState,
  getAllState,
  getSingleState,
  resetDataCreateAction,
  resetDataUpdateAction,
  updateState,
} from "../../../store/stateSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import InputEditBox from "../../../components/InputEditBox/InputEditBox";

function PopUpState({ handleClose, type, data }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");
  const [t, i18n] = useTranslation();

  let {
    data: states,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.states);

  let dispatch = useDispatch();

  useEffect(() => {
    if (dataCreate || dataUpdate) {
      dispatch(getAllState({ lang: i18n.language }));
      handleClose();
      dispatch(resetDataCreateAction(false));
      dispatch(resetDataUpdateAction(false));
    }
  }, [dataCreate, dataUpdate]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getSingleState({ id: data.id, lang: i18n.language }));
    }
  }, [type]);

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  let onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "add") {
      dispatch(createState({ data: inp, lang: i18n.language }));
    }
  };

  return (
    <Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            {type === "edit" ? (
              <span>
                {t("Edit")} {t("State")} {"&"} {t("cities")}
              </span>
            ) : (
              <span>
                {t("Add New")} {t("State")}
              </span>
            )}
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            {type === "add" ? (
              <div className="col-md-12 box-pop">
                <div className="form-group">
                  <label htmlFor="exampleInputSlug">
                    {t("State Name")}: <sup className="redstar">*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="exampleInputPassword1"
                    required
                    className="form-control"
                    placeholder="Enter the state name"
                    onChange={(e) => getValue(e)}
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="col-md-12 box-pop">
                  <div className="form-group">
                    <label htmlFor="exampleInputSlug">
                      {t("State Name")}: <sup className="redstar">*</sup>
                    </label>
                    <InputEditBox e={singleData && singleData} type="state" />
                  </div>
                </div>

                <div className="col-md-12 box-pop">
                  <div className="form-group">
                    <label htmlFor="exampleInputSlug">
                      {t("Cities Name")}: <sup className="redstar">*</sup>
                    </label>
                    {singleData &&
                      singleData.cities.map((e, index) => {
                        return <InputEditBox key={index} e={e} data={data} />;
                      })}
                  </div>
                </div>
              </Fragment>
            )}

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                type === "add" && (
                  <button type="submit" className="btn btn-primary-rgba">
                    <CheckCircleIcon color="#fff" />
                    {type === "edit" ? t("Edit") : t("Craete")}
                  </button>
                )
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </Fragment>
  );
}

export default PopUpState;
